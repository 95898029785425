import { Outlet } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
// components
import Logo from '../components/Logo';
import Scrollbar from '../components/Scrollbar';
import useAuth from '../hooks/useAuth';

// ----------------------------------------------------------------------

const HeaderStyle = styled('header')(({ theme }) => ({
  top: 0,
  left: 0,
  lineHeight: 0,
  width: '100%',
  position: 'absolute',
  padding: theme.spacing(3, 3, 0),
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(5, 5, 0)
  }
}));

// ----------------------------------------------------------------------

export default function LogoOnlyLayout() {
  const { user } = useAuth();

  // HACK: For testing purposes only
  console.debug('[LogoOnlyLayout] user', user);

  return (
    <>
      {/* TODO: revert when logo is ready */}
      {/* <HeaderStyle>
        <Logo />
      </HeaderStyle> */}
      <Scrollbar sx={{
        height: '100%',
      }}>
        <Outlet />
      </Scrollbar>
    </>
  );
}
