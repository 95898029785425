import {
  Suspense,
  lazy,
} from 'react';
import {
  Navigate,
  useRoutes,
  useLocation,
} from 'react-router-dom';
// layouts
import MainLayout from '../layouts/main';
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
// config
import { PATH_DASHBOARD } from './paths';
// components
import LoadingScreen from '../components/LoadingScreen';
import useAuth from '../hooks/useAuth';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {

  window.top.postMessage({
    type: 'iframe_init',
    // source: 'iframe', // TODO
    data: { url: window.location.href }
  }, '*');

  return useRoutes([
    // Main Routes
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <Register />
            </GuestGuard>
          ),
        },
        // { path: 'login-unprotected', element: <Login /> },
        // { path: 'register-unprotected', element: <Register /> },
        { path: 'reset-password', element: <ResetPassword /> },
        // { path: 'verify', element: <VerifyCode /> },
        { path: 'logout', element: <Logout /> },
      ],
    },
    {
      path: PATH_DASHBOARD.root,
      element: (
        <AuthGuard>
          {/* <LogoOnlyLayout /> */}
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <GeneralApp />, index: true },
        { path: 'profile/:username', element: <ProfileCRM /> },
        { path: 'fetching/:username', element: <StillFetching /> },
      ],
    },
    {
      path: PATH_DASHBOARD.billing.root,
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <UpgradePlan />, index: true },
        { path: 'success', element: <UpgradeSuccess /> },
      ],
    },
    {
      path: PATH_DASHBOARD.user.root,
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <UserAccount />, index: true },
      ],
    },
    {
      path: '*',
      element: <MainLayout />,
      children: [
        { path: 'privacy', element: <Privacy /> },
      ],
    },
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: 'pricing', element: <Pricing /> },
        // { path: 'coming-soon', element: <ComingSoon /> },
        { path: '500', element: <Page500 /> },
        { path: '404', element: <NotFound /> },
        { path: '400', element: <Page400 /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
    {
      path: '/',
      element: <Navigate to="/dashboard/" replace />,
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

// AUTHENTICATION
const Login = Loadable(lazy(() => import('../pages/auth/Login')));
const Register = Loadable(lazy(() => import('../pages/auth/Register')));
const ResetPassword = Loadable(lazy(() => import('../pages/auth/ResetPassword')));
const Logout = () => {
  const { logout } = useAuth();
  window.top.postMessage({
    type: 'logout',
  }, '*');
  logout();
  return <Navigate to="/" replace />;
}

//
const GeneralApp = Loadable(lazy(() => import('../pages/dashboard/GeneralApp')));
const ProfileCRM = Loadable(lazy(() => import('../pages/ProfileCRM')));
const UserAccount = Loadable(lazy(() => import('../pages/dashboard/UserAccount')));

//
const StillFetching = Loadable(lazy(() => import('../pages/StillFetching')));
const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const Page400 = Loadable(lazy(() => import('../pages/Page400')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));

//
const Privacy = Loadable(lazy(() => import('../pages/Privacy')));

//
const Pricing = Loadable(lazy(() => import('../pages/Pricing')));
const UpgradePlan = Loadable(lazy(() => import('../pages/UpgradePlan')));
const UpgradeSuccess = Loadable(lazy(() => import('../pages/UpgradeSuccess')));
