import PropTypes from 'prop-types';
import { createContext, useEffect, useReducer, useState } from 'react';
import { initializeApp } from 'firebase/app';
import {
  getAuth,
  signOut,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
} from 'firebase/auth';
import {
  getFirestore,
  collection,
  doc,
  setDoc,
  onSnapshot,
  serverTimestamp,
} from 'firebase/firestore';
import {
  getFunctions,
} from 'firebase/functions';
// import seedData from '../seed.firebase';
import { FIREBASE_API } from '../config';

// ----------------------------------------------------------------------

// const ADMIN_EMAILS = ['test@obezzad.me'];

const firebaseApp = initializeApp(FIREBASE_API);

const AUTH = getAuth(firebaseApp);

const DB = getFirestore(firebaseApp);

const functions = getFunctions(firebaseApp);

// seedData(firebaseApp, AUTH, DB); // HACK: for testing purposes only

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

const reducer = (state, action) => {
  if (action.type === 'INITIALISE') {
    const { isAuthenticated, user } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
    };
  }

  return state;
};

const AuthContext = createContext({
  ...initialState,
  method: 'firebase',
  login: () => Promise.resolve(),
  resetPassword: () => Promise.resolve(),
  register: () => Promise.resolve(),
  logout: () => Promise.resolve(),
});

// ----------------------------------------------------------------------

AuthProvider.propTypes = {
  children: PropTypes.node,
};

function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const [profile, setProfile] = useState(null);

  let unsubscribeUser = null;
  useEffect(
    () => {
      onAuthStateChanged(AUTH, async (user) => {
        if (user) {
          // Get user custom claims
          const tokenResult = await user.getIdTokenResult(true);

          const userRef = doc(DB, 'users', user.uid);

          if (unsubscribeUser) {
            unsubscribeUser();
            unsubscribeUser = null;
          }

          // FIXME: infinite loop???
          unsubscribeUser = onSnapshot(userRef, (snap) => {
            if (snap.exists()) {
              setProfile(snap.data());
              // FIXME: rename to payload everywhere
              window.top.postMessage({ type: 'SET_USER_DATA', data: snap.data() }, '*');
              // if (!snap.data().isLinked) {
              window.top.postMessage({
                type: 'init_linkedin',
                data: snap.data(),
              }, '*');
              // }
            }
          });

          // FIXME: move to cloud function
          let MAX_LISTS = 1;
          let MAX_FAVORITES = 20;

          if (tokenResult.claims.stripeRole === 'pro') {
            MAX_LISTS = 5;
            MAX_FAVORITES = 100;
          } else if (tokenResult.claims.stripeRole === 'expert') {
            MAX_LISTS = 10;
            MAX_FAVORITES = 500;
          }

          dispatch({
            type: 'INITIALISE',
            payload: {
              isAuthenticated: true,
              user: {
                ...user,
                _claims: {
                  MAX_LISTS,
                  MAX_FAVORITES,
                  ...tokenResult.claims,
                },
              },
            },
          });
        } else {
          if (unsubscribeUser) {
            unsubscribeUser();
            unsubscribeUser = null;
          }
          dispatch({
            type: 'INITIALISE',
            payload: { isAuthenticated: false, user: null },
          });
        }
      });
    },
    [dispatch]
  );

  const login = (email, password) => signInWithEmailAndPassword(AUTH, email, password);

  const resetPassword = (email) => {
    console.debug('resetPassword', email);
    sendPasswordResetEmail(AUTH, email)
      .then(() => {
        console.debug('resetPassword success');
        // FIXME: email sent but not received (SMTP?)
      })
      .catch((error) => {
        console.debug('resetPassword error', error);
        // TODO: handle error (show to user)
      });
  };

  const register = (email, password, firstName, lastName) =>
    createUserWithEmailAndPassword(AUTH, email, password)
      .then(async (res) => {
        const userRef = doc(collection(DB, 'users'), res.user?.uid);

        await setDoc(userRef, {
          uid: res.user?.uid,
          email,
          displayName: `${firstName} ${lastName}`,
          firstSignIn: true,
          isLinked: false,
          linkedinId: null,
          favorites: {
            _lists: {
              default: {
                id: 'default',
                name: 'Default',
                items: {
                  'ACoAAAAK8FYB-gd-PXEvI853P7cxv0BGGWVjycM': {
                    id: 'ACoAAAAK8FYB-gd-PXEvI853P7cxv0BGGWVjycM',
                    name: 'Aaron Zakowski',
                    avatar: 'https://firebasestorage.googleapis.com/v0/b/zammomagic.appspot.com/o/aaron.jpeg?alt=media&token=f5b0378c-917d-4703-b66a-0f2f4de5d12b',
                    username: 'aaronzakowski',
                  },
                },
              },
            },
            _count: 1,
            _lastUpdated: serverTimestamp(),
          },
          _lastUpdated: serverTimestamp(),
        });
      })
      .catch((err) => {
        if (err.code === 'auth/email-already-in-use') {
          return Promise.reject(new Error('Email already in use'));
        }
      });

  const logout = () => signOut(AUTH);

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'firebase',
        user: {
          ...(state?.user || {}),
          ...profile
          // photoURL: state?.user?.photoURL || profile?.photoURL,
          // displayName: state?.user?.displayName || profile?.displayName,
          // role: ADMIN_EMAILS.includes(state?.user?.email) ? 'admin' : 'user',
          // phoneNumber: state?.user?.phoneNumber || profile?.phoneNumber || '',
          // country: profile?.country || '',
          // address: profile?.address || '',
          // state: profile?.state || '',
          // city: profile?.city || '',
          // zipCode: profile?.zipCode || '',
          // about: profile?.about || '',
          // isPublic: profile?.isPublic || false,
        },
        login,
        resetPassword,
        register,
        logout,
        DB,
        functions,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
