import { useLocation, Outlet } from 'react-router-dom';
// @mui
import { Box, Link, Container, Typography, Stack } from '@mui/material';
// components
import Logo from '../../components/Logo';
//
import MainFooter from './MainFooter';
import MainHeader from './MainHeader';
import { HEADER } from '../../config';
import Scrollbar from '../../components/Scrollbar';

// ----------------------------------------------------------------------

export default function MainLayout() {
  const { pathname } = useLocation();

  const isHome = pathname === '/stats/';

  return (
    <Scrollbar sx={{
      height: '100%',
    }}>
      <Stack sx={{ minHeight: 1 }}>
        {!isHome && (<>
          <MainHeader />
          <Box sx={{
            minHeight: '100%',
            marginBottom: `${HEADER.MAIN_DESKTOP_HEIGHT}px`,
          }} />
        </>)}

        <Outlet />

        <Box sx={{ flexGrow: 1 }} />
      </Stack>
    </Scrollbar>
  );
}
