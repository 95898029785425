import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
// hooks
import useAuth from '../hooks/useAuth';
// pages
import Page400 from '../pages/Page400';
import Login from '../pages/auth/Login';
// components
import LoadingScreen from '../components/LoadingScreen';
import Scrollbar from '../components/Scrollbar';
import { PATH_DASHBOARD } from '../routes/paths';

// ----------------------------------------------------------------------

AuthGuard.propTypes = {
  children: PropTypes.node,
};

export default function AuthGuard({ children }) {
  const navigate = useNavigate();
  // TODO: add profile
  const { isAuthenticated, isInitialized, user } = useAuth();
  const { pathname } = useLocation();
  const [requestedLocation, setRequestedLocation] = useState(null);

  useEffect(() => {
    window.addEventListener('message', (event) => {
      const { type, data } = event.data;
      switch (type) {
        // case 'login':
        case 'url_changed':
          console.debug('[AuthGuard] URL changed:', data);
          if (data.url.includes('/feed/')) {
            setRequestedLocation(PATH_DASHBOARD.root);
          } else if (data.url.includes('/in/')) {
            setRequestedLocation(PATH_DASHBOARD.user.profile(data.url.split('/in/')[1].split('/')[0]));
          }
          break;
        case 'iframe_init':
          // NOTE: when we receive this message, it means the user is visiting the webapp directly
          console.debug('[AuthGuard] iframe init:', data);
          break;
        default:
          console.warn("Unhandled message from extension", event.data);
          break;
      }
    });
  }, []);

  if (!isInitialized) {
    return <LoadingScreen />;
  }

  if (!isAuthenticated) {
    if (pathname !== requestedLocation) {
      setRequestedLocation(pathname);
    }
    return <Login />;
  }

  if (isAuthenticated && !user.isLinked) {
    return <Page400 />;
  }
  if (requestedLocation && pathname !== requestedLocation) {
    setRequestedLocation(null);
    // console.warn('[AuthGuard] Redirecting to requested location', requestedLocation);
    navigate(requestedLocation);
    return <Navigate to={requestedLocation} />;
  }

  return (
    <Scrollbar>
      {children}
    </Scrollbar>
  );
}
