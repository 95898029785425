import { Box } from "@mui/material";

export default function LinkLionTextDark({ ...other }) {
	return (
		<Box {...other}>
			<svg viewBox="0 0 172 38" fill="none" xmlns="http://www.w3.org/2000/svg">
				<g clipPath="url(#clip0_122_773)">
					<path d="M3.24747 4.70203V34.2975H14.887V36.9244H0V4.70203H3.24747Z" fill="#141414" />
					<path d="M18.7808 4.61145C18.7808 3.22802 19.8494 2.26456 21.1418 2.26456C22.4342 2.26456 23.5111 3.23625 23.5111 4.61145C23.5111 5.98664 22.4425 7.00774 21.1418 7.00774C19.8412 7.00774 18.7808 5.99488 18.7808 4.61145ZM19.5264 11.7592H22.7738V36.9244H19.5264V11.7509V11.7592Z" fill="#141414" />
					<path d="M49.1096 22.5384C49.1096 16.9635 46.0527 14.106 41.4135 14.106C36.7742 14.106 33.3942 17.0541 33.3942 23.1889V36.9244H30.1467V11.7509H33.3942V16.0412C35.0179 12.9038 38.2654 11.2898 42.0182 11.2898C47.7676 11.2898 52.3157 14.7895 52.3157 22.1678V36.9162H49.1179V22.5384H49.1096Z" fill="#141414" />
					<path d="M59.4983 2.80804H62.7458V22.9995L72.9438 11.7509H77.4919L65.4796 24.2924L77.5831 36.9244H73.1758L62.7458 25.6346V36.9244H59.4983V2.80804Z" fill="#141414" />
					<path d="M88.8498 4.56201V30.8389H99.2384V36.9244H80.9216V4.56201H88.8498Z" fill="#FBBE28" />
					<path d="M102.304 4.29028C102.304 1.89398 104.209 0 107.034 0C109.859 0 111.715 1.88575 111.715 4.29028C111.715 6.69481 109.809 8.53115 107.034 8.53115C104.259 8.53115 102.304 6.63717 102.304 4.29028ZM103.049 11.2074H110.977V36.9326H103.049V11.1992V11.2074Z" fill="#FBBE28" />
					<ellipse cx="129.228" cy="23.34" rx="13.3684" ry="13.3684" fill="#FBBE28" />
					<path d="M128.72 15.6068L126.557 19.9736C126.482 20.1235 126.344 20.2279 126.184 20.2547L121.637 21.0178C121.23 21.0861 121.065 21.6039 121.351 21.9132L124.689 25.5155C124.799 25.6344 124.85 25.8 124.827 25.9636L124.149 30.7273C124.089 31.1513 124.509 31.4719 124.878 31.2835L128.952 29.2033C129.091 29.1325 129.253 29.131 129.393 29.1994L133.711 31.3098C134.079 31.4895 134.491 31.171 134.433 30.7518L133.769 25.956C133.747 25.7967 133.795 25.6356 133.9 25.5176L137.112 21.9111C137.39 21.5991 137.222 21.09 136.819 21.0231L132.181 20.2541C132.022 20.2276 131.883 20.1239 131.809 19.9744L129.626 15.6052C129.435 15.2228 128.91 15.2237 128.72 15.6068Z" fill="white" />
					<path d="M164.122 22.9583C164.122 19.4586 162.175 17.5234 159.159 17.5234C156.144 17.5234 154.197 19.4586 154.197 22.9583V36.9244H146.269V11.1992H154.197V14.6083C155.771 12.4426 158.555 10.9192 162.034 10.9192C168.015 10.9192 172.008 14.9789 172.008 21.8878V36.9162H164.122V22.9583Z" fill="#FBBE28" />
				</g>
				<defs>
					<clipPath id="clip0_122_773">
						<rect width="172" height="37.2867" fill="white" />
					</clipPath>
				</defs>
			</svg>
		</Box>
	)
}
