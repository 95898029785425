// ----------------------------------------------------------------------

export const path = (root, sublink) => {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';
const ROOTS_BILLING = '/upgrade';
const ROOTS_ACCOUNT = '/account';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  register: path(ROOTS_AUTH, '/register'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  verify: path(ROOTS_AUTH, '/verify'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
};

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page404: '/404',
  page500: '/500',
  components: '/components',
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  billing: {
    root: path(ROOTS_BILLING, '/'),
  },
  user: {
    root: path(ROOTS_ACCOUNT, '/'),
    profile: (userSlug) => path(ROOTS_DASHBOARD, `/profile/${userSlug}`),
    linkedIn: (userSlug) => `https://www.linkedin.com/in/${userSlug}`,
  },
  general: {
    dashboard: path(ROOTS_DASHBOARD, '/'),
  },
};

export const PATH_CONTACT = 'mailto:support@linklion.io'; // TODO: check this with Aaron for future feature
