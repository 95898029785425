import { Link as RouterLink } from 'react-router-dom';
import { createTheme, styled, ThemeProvider } from '@mui/material/styles';
// @mui
import { Box, Button, Typography, Container } from '@mui/material';
// components
import Page from '../components/Page';
import { LinkedInNotSyncIllustration } from '../assets';
import useAuth from '../hooks/useAuth';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  height: '100%',
  alignItems: 'center',
  paddingTop: theme.spacing(15),
  paddingBottom: theme.spacing(10),
  backgroundImage: 'url(/images/cover.png)',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
}));

// ----------------------------------------------------------------------

export default function Page400() {

  const { user } = useAuth();

  return (
    <Page title="LinkedIn Not Initialized" sx={{ height: 1 }}>
      <RootStyle>
        <Container>
          <Box sx={{
            margin: 'auto',
            textAlign: 'center',
          }}>
            <LinkedInNotSyncIllustration sx={{ height: 260, my: 5 }} />

            <Box sx={{ mb: 5 }}>
              <Typography variant="h1" sx={{
                mb: 2,
              }}>
                Oops! It looks like you haven't connected your LinkedIn account yet.
              </Typography>
              <Typography sx={{
                color: 'text.secondary'
              }}>Please activate the extension and ensure you're logged in LinkedIn initially, then try again.</Typography>
            </Box>

            <Box sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
              gap: 2,
            }}>
              <Button to="/" size="large" variant="contained" component={RouterLink}>
                Download the extension
              </Button>
              <ThemeProvider theme={(theme) => createTheme({
                ...theme,
                palette: {
                  ...theme.palette,
                  primary: {
                    main: '#056FD6',
                  },
                },
              })}>
                <Box sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  gap: 1,
                }}>
                  <Button to="/auth/logout" size="large" component={RouterLink}>
                    Logout
                  </Button>
                  <Button size="large" variant="outlined" onClick={() => {
                    window.top.postMessage({
                      type: 'init_linkedin',
                      data: user,
                    }, '*');
                  }}>
                    Retry
                  </Button>
                </Box>
              </ThemeProvider>
            </Box>
          </Box>
        </Container>
      </RootStyle>
    </Page>
  );
}
