import { capitalCase } from 'change-case';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Box, Card, Stack, Link, Alert, Tooltip, Container, Typography } from '@mui/material';
import { LinkLionTextLight } from '../../assets';
// routes
import { PATH_AUTH } from '../../routes/paths';
// hooks
import useAuth from '../../hooks/useAuth';
import useResponsive from '../../hooks/useResponsive';
// components
import Page from '../../components/Page';
import Logo from '../../components/Logo';
import Image from '../../components/Image';
// sections
import { LoginForm } from '../../sections/auth/login';


// ----------------------------------------------------------------------

// FIXME: responsiveness
const RootStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  backgroundColor: '#1B2757', // HACK: get from theme
  color: theme.palette.common.white,
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function Login() {
  return (
    <Page title="Login">
      <RootStyle>
        <Container>
          <ContentStyle>
            <Stack direction="column" alignItems="center" gap={2} sx={{ mb: 5 }}>
              {/* TODO: Accessibility */}
              <Logo disabledLink />
              <LinkLionTextLight sx={{ height: 20 }} />
            </Stack>
            {/*
            <Alert severity="info" sx={{ mb: 3 }}>
              Use email : <strong>test@obezzad.me</strong> / password :<strong> test123</strong>
            </Alert> */}

            <LoginForm />

            <Typography variant="body2" align="center" sx={{ mt: 3 }}>
              Don’t have an account?{' '}
              <Link variant="subtitle2" component={RouterLink} to={PATH_AUTH.register}>
                Get started
              </Link>
            </Typography>
          </ContentStyle>
        </Container>
      </RootStyle>
    </Page>
  );
}
