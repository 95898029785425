import { Box } from "@mui/material";

export default function LinkLionTextLight({ ...other }) {
	return (
		<Box {...other}>
			<svg viewBox="0 0 97 22" fill="none" xmlns="http://www.w3.org/2000/svg">
				<g clipPath="url(#clip0_119_898)">
					<path d="M1.82191 3.04364V19.6474H8.35195V21.1211H0V3.04364H1.82191Z" fill="white" />
					<path d="M10.5364 2.99281C10.5364 2.21667 11.1359 1.67615 11.861 1.67615C12.586 1.67615 13.1902 2.22129 13.1902 2.99281C13.1902 3.76432 12.5907 4.33718 11.861 4.33718C11.1313 4.33718 10.5364 3.76894 10.5364 2.99281ZM10.9547 7.00284H12.7766V21.1211H10.9547V6.99822V7.00284Z" fill="white" />
					<path d="M27.5517 13.0503C27.5517 9.92261 25.8367 8.31952 23.2339 8.31952C20.6312 8.31952 18.735 9.97343 18.735 13.4152V21.1211H16.913V6.99824H18.735V9.40519C19.6459 7.64502 21.4678 6.73953 23.5732 6.73953C26.7987 6.73953 29.3503 8.70297 29.3503 12.8424V21.1165H27.5563V13.0503H27.5517Z" fill="white" />
					<path d="M33.3799 1.98108H35.2018V13.309L40.9232 6.99824H43.4748L36.7356 14.0343L43.5259 21.1211H41.0533L35.2018 14.7873V21.1211H33.3799V1.98108Z" fill="white" />
					<path d="M49.8468 2.96509V17.707H55.675V21.1211H45.3989V2.96509H49.8468Z" fill="#FBBE28" />
					<path d="M57.3947 2.81264C57.3947 1.46827 58.4637 0.405701 60.0485 0.405701C61.6334 0.405701 62.6745 1.46365 62.6745 2.81264C62.6745 4.16164 61.6055 5.19187 60.0485 5.19187C58.4915 5.19187 57.3947 4.1293 57.3947 2.81264ZM57.813 6.69332H62.2608V21.1257H57.813V6.6887V6.69332Z" fill="#FBBE28" />
					<circle cx="72.5" cy="13.5" r="7.5" fill="#FBBE28" />
					<path d="M72.2148 9.16148L71.0011 11.6113C70.9594 11.6955 70.8819 11.754 70.7923 11.769L68.2411 12.1972C68.0127 12.2355 67.9199 12.526 68.0807 12.6995L69.9532 14.7205C70.015 14.7872 70.0436 14.8801 70.0306 14.9719L69.6504 17.6444C69.6166 17.8823 69.8524 18.0622 70.0594 17.9565L72.3449 16.7894C72.4228 16.7497 72.5137 16.7489 72.5922 16.7872L75.0149 17.9712C75.2212 18.072 75.4522 17.8933 75.4197 17.6581L75.0473 14.9676C75.0349 14.8783 75.062 14.7878 75.1209 14.7217L76.9231 12.6983C77.079 12.5233 76.9846 12.2376 76.7584 12.2001L74.1566 11.7687C74.067 11.7539 73.9895 11.6957 73.9477 11.6118L72.7229 9.16056C72.6158 8.94602 72.3213 8.94655 72.2148 9.16148Z" fill="white" />
					<path d="M92.076 13.2858C92.076 11.3224 90.9837 10.2367 89.292 10.2367C87.6002 10.2367 86.508 11.3224 86.508 13.2858V21.1211H82.0601V6.68869H86.508V8.60131C87.3911 7.38629 88.9527 6.53162 90.9047 6.53162C94.2604 6.53162 96.5006 8.8092 96.5006 12.6853V21.1165H92.076V13.2858Z" fill="#FBBE28" />
				</g>
				<defs>
					<clipPath id="clip0_119_898">
						<rect width="96.4959" height="20.9187" fill="white" transform="translate(0 0.405701)" />
					</clipPath>
				</defs>
			</svg>
		</Box>
	)
}
